<template>
  <div class="page">
    <div class="page-header">
      <el-button type="primary" icon="el-icon-plus" @click="addItem" v-has="'add'">
        {{ $t('sipServer.addServer') }}
      </el-button>
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-delete iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              size="small"
              :placeholder="$t('sipServer.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <table-pagination
        memory="sipServer"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="filterTableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- sipServer -->
        <template slot="sipServer" slot-scope="scope">
          {{scope.row.sipServer}}{{scope.row.port==5060 ? '' : `:${scope.row.port}`}}{{`(${scope.row.serverName})`}}
        </template>
        <template slot="sipServerHeader">
          <div v-if="!serverOptions.length">{{$t('sipAccount.sipServer')}}</div>
          <el-popover v-else trigger="click">
            <div class="server-title" slot="reference">{{$t('sipAccount.sipServer')}}<i class="el-icon-arrow-down el-icon--right" ></i></div>
            <el-checkbox
              class="check-all"
              key="allSelect"
              v-model="checkAllServer"
              @change="changeAllServer"
            >{{ $t('all') }}</el-checkbox>
            <el-checkbox-group
              class="server-list"
              v-model="serverList"
            >
              <el-checkbox
                v-for="server in serverOptions"
                :key="server.value"
                :label="server.text"
              ></el-checkbox>
            </el-checkbox-group>
          </el-popover>
        </template>
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('edit')"
            placement="top"
            :open-delay="1000"
            v-has="'edit'"
          >
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)" :disabled="scope.row.origin==2" >
              <i class="el-icon-edit iconfont icon-edit" ></i>
            </el-button>
          </el-tooltip>
          <!-- <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-has="'delete'"
          >
            <el-button type="text" class="btn-p0" @click="deleteItem(scope.row.id)">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip> -->
        </template>
      </table-pagination>
    </div>
    <!-- 添加服务 -->
    <!-- <ServerDetails ref="addServer" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { serverSearch, removeServer, removeServerBatch } from '@/api/sipServer'
import { TablePagination } from '@/components'
// import ServerDetails from './ServerDetails'
import mixinTable from '@/components/mixin/table'

export default {
  name: 'SipServer',
  mixins: [mixinTable],
  components: {
    TablePagination,
    // ServerDetails
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: null,
        site: []
      },
      allOptions: [],        // 全部选项
      serverList: [],        // 选择的服务
      serverOptions: [],     // 可选服务
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('sipServer.serverName'),
          prop: 'serverName',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('sipServer.sipServer'),
          prop: 'sipServer',
          minWidth: 200,
          // filterEnable: true,
          slot: true,
          headerSlot: true,
        },
        {
          label: this.$t('sipServer.accountNumber'),
          prop: 'accountNumber',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('ssite.site'),
          prop: 'siteName',
          minWidth: 150,
          filterEnable: true,
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapGetters('session', ['pageTabelHeight']),
    // 过滤表格数据
    filterTableData() {
      let list = []
      for (let row of this.tableData) {
        if (this.serverList.indexOf(row.sipServer) != -1) {
          list.push(row)
        }
      }
      return list
    },
    // 服务全选
    checkAllServer: {
      get() {
        return this.serverOptions.length == this.serverList.length
      },
      set() {}
    }
  },
  created() {
    this.tableInit()
  },
  methods: {
    // 全选服务
    changeAllServer(val) {
      console.log('全选', val)
      // this.serverList = null
      this.serverList = val ? this.allOptions : []
    },
    // 获取服务选项
    getServerOptions() {
      let arr = []
      if (Array.isArray(this.tableData)) {
        this.tableData.forEach((row) => {
          if (!arr.includes(row.sipServer)) {
            arr.push(row.sipServer)
          }
        })
      }
      this.allOptions = arr
      this.serverList = arr
      this.serverOptions = arr.map((name) => {
        return {
          text: name,
          value: name,
        }
      })
    },
    // 获取数据
    getTabelData() {
      serverSearch(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows[0].list // 表格数据
          this.tabelTotal = data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
          this.getServerOptions()
        })
        .catch(() => {})
    },
    // 增
    addItem() {
      this.$router.push('/sipServer/addServer')
      // this.$refs.addServer.openDialog()
    },
    // 编辑
    editItem(id) {
      this.$router.push({ path: '/sipServer/editServer', query: { id } })
    },
    // 删
    deleteItem(id) {
      this.$confirm(this.$t('sipServer.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          if (id) {
            removeServer({
              ids: this.tableSelection.map((e) => e.id),
              args: this.tableSelection.map((e) => e.serverName),
            })
              .then((res) => {
                this.$message.success(res.data.message)
                this.getTabelData()
              })
              .catch(() => {})
          } else {
            removeServerBatch({
              ids: this.tableSelection.map((e) => e.id),
              args: this.tableSelection.map((e) => e.serverName),
            })
              .then((res) => {
                this.$message.success(res.data.message)
                this.getTabelData()
              })
              .catch(() => {})
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.check-all {
  display: flex;
  padding: 0 10px;
}
.server-title {
  &:hover, &:active {
    cursor: pointer;
  }
}
.server-list {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
</style>
