import { mapState } from 'vuex'

export default {
  data() {
    return {
      tableSelection: []
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
  },
  watch: {
    'sidebarSite'(val, oldVal) {
      if(JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.tabelSearch.site = val
        this.getTabelData()
      }
    },
  },
  methods: {
    tableInit() {
      this.tabelSearch.site = this.sidebarSite
      this.getTabelData()
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'updateTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    }
  }
}
